<template>
  <div v-if="!v2Flag" class="tw-flex tw-h-screen tw-flex-col tw-justify-between">
    <SiteHeader />
    <div
      class="tw-mt-4 tw-flex tw-items-center tw-justify-center tw-gap-10 tw-px-8 xl:tw-mt-[100px]"
    >
      <div class="tw-max-w-[660px]">
        <div
          class="tw-mb-4 tw-font-poppins tw-text-[32px] tw-font-bold tw-leading-[40px] tw-text-gray-800 md:tw-mt-20 md:!tw-text-[64px] md:!tw-leading-[74px]"
        >
          {{ message }}
        </div>

        <div v-if="hasContactInfo">
          <p
            class="tw-mb-10 tw-text-[14px] tw-leading-[150%] tw-text-gray-600 md:tw-text-base md:!tw-leading-[28px]"
          >
            Please do not hesitate to contact our office with any questions:
          </p>

          <div class="tw-flex tw-flex-wrap tw-gap-10 tw-text-base">
            <a v-if="emailAddress" :href="`mailto:${emailAddress}`">
              <div class="tw-flex tw-gap-4">
                <div
                  class="tw-flex tw-h-[56px] tw-w-[56px] tw-content-center tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-100"
                >
                  <EmailIcon class="tw-text-gray-800" />
                </div>
                <div>
                  <div class="tw-font-bold tw-text-gray-800 md:tw-text-base">Email us at</div>
                  <div class="tw-break-all tw-text-gray-600">
                    {{ emailAddress }}
                  </div>
                </div>
              </div>
            </a>

            <a v-if="phoneNumber" :href="`tel:+1${phoneNumber}`">
              <div class="tw-flex tw-gap-4">
                <div
                  class="tw-flex tw-h-[56px] tw-w-[56px] tw-content-center tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-100"
                >
                  <PhoneIcon class="tw-text-gray-800" />
                </div>
                <div>
                  <div class="tw-font-bold tw-text-gray-800 md:tw-text-base">Call us at</div>
                  <div class="tw-text-gray-600">
                    {{ phoneNumber }}
                  </div>
                </div>
              </div>
            </a>

            <a v-if="phoneNumber" :href="`sms:+1${phoneNumber}`">
              <div class="tw-flex tw-gap-4">
                <div
                  class="tw-flex tw-h-[56px] tw-w-[56px] tw-content-center tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-100"
                >
                  <SMSIcon class="tw-text-gray-800" />
                </div>
                <div>
                  <div class="tw-font-bold tw-text-gray-800 md:tw-text-base">Text us at</div>
                  <div class="tw-text-gray-600">
                    {{ phoneNumber }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="tw-hidden md:tw-block"><ExclamationIcon /></div>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import { BrandingState } from './composables/useBrand'

import ExclamationIcon from '~/assets/icons/exclamation.svg'
import { formatPhoneNumber } from '~/utils/phone-numbers'
import SMSIcon from '~icons/heroicons-solid/chat-bubble-bottom-center-text'
import EmailIcon from '~icons/heroicons-solid/envelope'
import PhoneIcon from '~icons/heroicons-solid/phone'

const { $flagsmith } = useNuxtApp()

const v2Flag = computed(() => {
  return $flagsmith.hasFeature('case_wise_2')
})

const branding = useBrand() as Ref<BrandingState>
const props = defineProps<{
  error: any
}>()

if (v2Flag.value) {
  const errorMessage = computed(() => props.error.message)
  if (errorMessage.value.includes('Page not found')) {
    navigateTo('/v2/error/page-not-found')
  } else if (errorMessage.value.includes('internal server error')) {
    navigateTo('/v2/error/internal-error')
  } else if (errorMessage.value.includes('expiredinvite')) {
    navigateTo('/v2/error/expired-link')
  }
}

const message = computed(() => {
  const errorMessage = props.error?.message?.toLowerCase()
  if (props.error?.statusCode === 404 || errorMessage?.includes('page not found')) {
    return "Sorry, we couldn't find that page."
  }

  if (errorMessage?.includes('expiredinvite')) {
    return 'Sorry, this invitation link has expired.'
  }

  if (errorMessage?.includes('expiredlogin')) {
    return 'Sorry, this login link has expired.'
  }

  if (errorMessage?.includes('invalidtoken')) {
    return 'Sorry, this link is invalid.'
  }

  return 'Something went wrong'
})

const emailAddress = computed(() => {
  const brandingState = branding as Ref<BrandingState>
  return brandingState.value.supportEmail ?? null
})

const phoneNumber = computed(() => {
  const brandingState = branding as Ref<BrandingState>
  return formatPhoneNumber(brandingState.value.outreachNumber) ?? null
})

const hasContactInfo = computed(() => {
  return !!(emailAddress || phoneNumber)
})
</script>
